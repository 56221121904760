import React from 'react';
import call from '../../../assets/Svgs/call.svg';
import message from '../../../assets/Svgs/message.svg';
import location from '../../../assets/Svgs/location.svg';
import InputField from './InputField';
import SelectInput from './SelectInput';
import light from '../../../assets/Svgs/light.svg';

const Contacts = () => {
  const contacts = [
    {
      id: 1,
      title: 'Address',
      icon: location,
      content:
        '2, Akinremi Street, Anifowoshe Junction. Computer Village. Ikeja',
    },
    {
      id: 2,
      title: 'Phone number',
      icon: call,
      content: '+234 706 122 5320',
      content2: '+234 805 808 5008',
    },
    {
      id: 3,
      title: 'Email Address',
      icon: message,
      content: 'info@nasmajhubsservices.com',
    },
  ];

  const options = [
    'Select service',
    'Renewable Solar Solutions',
    'Surveillance & Security Solutions',
    'Smart Home & Automation',
    'Building Maintenance & Construction',
    'Fire Alarm System',
    'General Contract',
    'Street Lighting Installation',
    'Network Infrastructure',
  ];

  return (
    <section>
      <div className='lg:h-[14.8125rem] h-[29.8125rem] md:h-[12.5125rem] bg-[#002C54] pt-[2.87rem] md:pt-[3.54rem] lg:pt-[4.44rem]'>
        <div className='flex flex-col justify-center items-center gap-[2.87rem] md:gap-0 md:flex-row md:justify-evenly'>
          {contacts.map((con) => (
            <article
              key={con.id}
              className='w-[17.5625rem] h-[5.5625rem] md:w-[13.5625rem] lg:w-[18.5625rem] xl:w-[21.5625rem] flex items-center px-[1.5rem] md:px-[0.8rem] lg:p-[1rem] xl:p-[1.84rem] gap-5 md:gap-2 lg:gap-3 md:h-[5.3625rem] lg:h-[6.5625rem] rounded-md lg:rounded-[0.625rem] border-[1px] border-[#47FFF5] shadow-md'
            >
              <img
                src={con.icon}
                alt='icons'
                className='md:w-7 md:h-7 w-10 h-10 lg:w-auto lg:h-auto'
              />

              <span>
                <h3 className='text-white afacad font-semibold leading-normal md:text-sm lg:text-xl'>
                  {con.title}
                </h3>
                <span>
                  <p className='afacad font-normal text-white leading-normal text-xs lg:text-base'>
                    {con.content}
                  </p>
                  {/* <p className='afacad font-normal text-white leading-normal text-xs lg:text-base'>
                    {con.content2}
                  </p> */}
                </span>
              </span>
            </article>
          ))}
        </div>
      </div>
      <article className='px-2 small:px-0 small:max-w-[21rem] md:max-w-[42rem] lg:max-w-[55rem] xl:max-w-[65rem] mac:max-w-[72rem] mx-auto'>
        <h3 className='text-[#002C54] mt-[2.65rem] leading-[1.75rem] text-lg small:text-[1.375rem] md:mt-[3.38rem] lg:mt-[4.38rem] xl:mt-[6.38rem] pb-[2.5rem] md:pb-[3.5rem] text-center afacad md:text-xl lg:text-[2rem] font-normal md:leading-[2.1875rem]'>
          Drop us a message and we will get <br />
          back to you as soon as possible.
        </h3>
        <div className='space-y-[1.2rem] md:space-y-[2.62rem]'>
          <span className='flex flex-col gap-[1.2rem] md:flex-row md:justify-between md:gap-7'>
            <InputField label='Name' name='Name' />
            <InputField label='Email' name='Email' />
          </span>
          <span className='flex flex-col gap-[1.2rem] md:flex-row md:justify-between md:gap-7'>
            <InputField label='Phone number' name='Phone number' />
            <SelectInput label='Service' name='service' options={options} />
          </span>
          <div className='flex flex-col gap-2 pt-[1.2rem] md:pt-0'>
            <label className='text-[#002C54]  leading-[1.90494rem] text-[1.25rem] md:text-lg lg:text-[1.5rem] font-normal afacad'>
              Message
            </label>
            <textarea className='w-full pl-5 h-[19.25rem] border-[0.8px] border-[#002C54] rounded-[0.625rem] shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm' />
          </div>
          <button className='px-[2.3rem] py-[0.8rem] lg:py-[1.06rem] text-[#002C54] text-xl lg:text-[1.5rem] leading-normal font-semibold afacad bg-[#FFDF53] rounded-full'>
            Send message{' '}
          </button>
        </div>
      </article>
      <div className='justify-end hidden md:flex -mt-52'>
        <img src={light} alt='' className='relative -z-10' />
      </div>
    </section>
  );
};

export default Contacts;
