import React from 'react';

const SelectInput = ({ label, options, name }) => {
  return (
    <div className='relative md:w-[20.5125rem] lg:w-[27.8125rem] xl:w-[30.8125rem] mac:w-[34.8125rem]'>
      <label
        htmlFor={name}
        className='text-[#002C54] text-[1.25rem] md:text-lg lg:text-[1.5rem] font-normal leading-[1.90494rem] afacad mb-2 block'
      >
        {label}
      </label>
      <select
        id={name}
        name={name}
        className='block w-full border border-[#002C54] rounded-[0.625rem] h-[3.5125rem] lg:h-[4.125rem] py-2 pl-3 pr-10 text-[#002C54E5] focus:outline-none focus:ring-indigo-400 focus:border-indigo-400 text-sm small:text-base lg:text-lg appearance-none'
      >
        {options.map((option, index) => (
          <option key={index} value={option} className='font-medium'>
            {option}
          </option>
        ))}
      </select>

      {/* Custom Dropdown Arrow */}
      <div className='pointer-events-none font-semibold absolute top-9 md:top-7 bottom-0 right-0 flex items-center pr-3'>
        <svg
          className='h-7 w-7 text-[#002C54]'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
          fill='currentColor'
          aria-hidden='true'
        >
          <path
            fillRule='evenodd'
            d='M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 01.02-1.06z'
            clipRule='evenodd'
          />
        </svg>
      </div>
    </div>
  );
};

export default SelectInput;
